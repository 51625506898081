import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import React from 'react';

import { Footer, Header } from 'components/common';

import { useClientBranding } from '../Configuration/hooks';
import { usePaymentLayoutStyles } from './styles';

export const PaymentLayout: React.FunctionComponent = ({ children }): JSX.Element => {
	const { logo, header, footer } = useClientBranding();
	const styles = usePaymentLayoutStyles();
	const { logoOnly, links: headerLinks, text: headerText, hasBackgroundColor: headerHasBackround } = header;
	const { links: footerLinks, text: footerText, logo: footerLogo, hasBackgroundColor: footerHasBackground } = footer;

	return (
		<Container className={styles.root} disableGutters component="main" maxWidth={false} classes={{ root: styles.root }}>
			<Box className={styles.container} boxShadow={3}>
				<Header
					logo={logo}
					text={(!logoOnly && headerText) || undefined}
					hasBackground={headerHasBackround}
					links={headerLinks}
				/>
				{children}
			</Box>
			<Footer text={footerText} links={footerLinks} logo={footerLogo} hasBackground={footerHasBackground} />
		</Container>
	);
};
