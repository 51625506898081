import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import confirmationImage from '../../../theme/assets/images/payment_confirmation.svg';
import {
	usePaymentParameters,
	useGetIsQuickPayPage,
	useClientBranding,
	useGetIsPayByLink,
} from '../Configuration/hooks';
import { PaymentLayout } from '../Payment/PaymentLayout';
import { getReceipt, printDocument } from './GetReceipt';
import { usePaymentFeedbackStyles } from './styles';

export const PaymentConfirmation: React.FunctionComponent<{ identifier?: string }> = ({ identifier }) => {
	const history = useHistory();
	const { returnUrl, extendedUrl } = usePaymentParameters();
	const intl = useIntl();
	const styles = usePaymentFeedbackStyles();
	const isQuickPayPage = useGetIsQuickPayPage();
	const branding = useClientBranding();
	const isPayByLink = useGetIsPayByLink();
	const [isPrintMessageVisible, togglePrintMessage] = useState(false);

	const { showPrintReceipt } = branding;

	const [isPrintReceiptLoading, setIsPrintReceiptLoading] = useState(false);
	const receiptMessage = intl.formatMessage({ id: 'payment-confirmation__receipt' });
	const title = intl.formatMessage({ id: 'payment-confirmation__title' });
	const homeBtnLabel = intl.formatMessage({ id: 'button__home' });
	const printReceiptButtonText = intl.formatMessage({ id: 'button__receipt' });

	const onReturn = () => {
		if (isQuickPayPage) {
			window.location.replace('/payments');
			return;
		}

		if (returnUrl) {
			window.location.replace(returnUrl);
			return;
		}

		if (extendedUrl) {
			window.location.replace(extendedUrl);
			return;
		}

		history.goBack();
	};

	const printReceipt = () => {
		if (identifier) {
			setIsPrintReceiptLoading(true);
			printDocument(getReceipt(identifier), togglePrintMessage).then(() => {
				setIsPrintReceiptLoading(false);
			});
		}
	};

	return (
		<PaymentLayout>
			<Box className={styles.root}>
				<img className={styles.image} src={confirmationImage} alt="Payment confirmation" />
				<h1 color="primary" className={styles.subtitle}>
					{title}
				</h1>
				<Divider flexItem className={styles.divider} />
				<Typography variant="body2" color="primary" align="center" className={styles.additionalMessage}>
					{receiptMessage}
				</Typography>
				{identifier && showPrintReceipt && (
					<>
						{isPrintMessageVisible && isPrintReceiptLoading && (
							<Typography color="primary" align="center" className={styles.printReceiptMessage}>
								{intl.formatMessage({ id: 'payment-confirmation__receipt_open_message' })}
							</Typography>
						)}
						<Button
							variant="contained"
							color="secondary"
							startIcon={<Icon>receipt</Icon>}
							onClick={printReceipt}
							size="large"
							className={styles.receiptBtn}
							aria-label={printReceiptButtonText}
						>
							{printReceiptButtonText}
							{isPrintReceiptLoading && <CircularProgress size={20} className={styles.buttonProgress} />}
						</Button>
					</>
				)}
				{!isPayByLink && (
					<Button
						variant="contained"
						color="secondary"
						startIcon={<Icon>home</Icon>}
						onClick={onReturn}
						size="large"
						className={styles.returnBtn}
						aria-label={homeBtnLabel}
					>
						{homeBtnLabel}
					</Button>
				)}
			</Box>
		</PaymentLayout>
	);
};
