import { useContext, Context } from 'react';

import { ConfigurationContext } from './ConfigurationContext';
import { PaymentSystemBaseConfiguration, ConfigurationWithPaymentData } from './types';

export const useConfiguration = <T extends PaymentSystemBaseConfiguration = PaymentSystemBaseConfiguration>() =>
	useContext<ConfigurationWithPaymentData<T>>(
		(ConfigurationContext as unknown) as Context<ConfigurationWithPaymentData<T>>
	);

export const usePaymentSystemConfiguration = <
	T extends PaymentSystemBaseConfiguration = PaymentSystemBaseConfiguration
>() => useConfiguration<T>().config.paymentSystem;

export const useClientID = () => useConfiguration().config.clientID;

export const usePaymentParameters = () => useConfiguration().data.parameters;

export const usePaymentConfiguration = <T extends PaymentSystemBaseConfiguration = PaymentSystemBaseConfiguration>() =>
	useConfiguration<T>().config;

export const useClientBranding = () => useConfiguration().config.branding;

export const useAmountValidation = () => useConfiguration().config.amountValidation;

export const useIsEmailMandatory = () => useConfiguration().config.isEmailMandatory;

export const useIsEmailUneditable = () => useConfiguration().config.isEmailUneditable;

export const useGetIsQuickPayPage = () => useConfiguration().isQuickPayPage;

export const useGetIsDraft = () => useConfiguration().isDraft;

export const useGetIsPayByLink = () => useConfiguration().config.payByLinkConfiguration?.isPayByLink;
