import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export const useLoadingStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			flex: 1,
			height: '100%',
			backgroundColor: theme.palette.custom
				? theme.palette.custom.main
				: theme.palette.primary[(700 as unknown) as keyof PaletteColor],
		},
		spinner: {
			margin: theme.spacing(6, 'auto'),
			position: 'relative',
		},
		cube: {
			backgroundColor: theme.palette.secondary.main,
			width: 15,
			height: 15,
			position: 'absolute',
			top: 0,
			left: 0,
			animation: `$rotating 1.8s infinite ease-in-out`,
		},
		secondCube: {
			backgroundColor: theme.palette.info[('A700' as unknown) as keyof PaletteColor],
			animationDelay: '-0.9s',
		},
		'@keyframes rotating': {
			'25%': {
				transform: 'translateX(42px) rotate(-90deg) scale(0.5)',
			},
			'50%': {
				transform: 'translateX(42px) translateY(42px) rotate(-179deg)',
			},
			'50.1%': {
				transform: 'translateX(42px) translateY(42px) rotate(-180deg)',
			},
			'75%': {
				transform: 'translateX(0px) translateY(42px) rotate(-270deg) scale(0.5)',
			},
			'100%': {
				transform: 'rotate(-360deg)',
			},
		},
	})
);
