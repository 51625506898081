import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useHeaderStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: (props: { hasBackground?: boolean }) => ({
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			flexWrap: 'wrap',
			backgroundColor: props.hasBackground ? theme.palette.secondary.main : 'transparent',
			padding: theme.spacing(5, 6),
			borderTopLeftRadius: 15,
			borderTopRightRadius: 15,
			borderBottom: !props.hasBackground ? `1px solid ${theme.palette.divider}` : undefined,
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(3, 2),
			},
		}),
		headerText: (props: { hasBackground?: boolean }) => ({
			fontWeight: 700,
			marginLeft: theme.spacing(4),
			fontSize: 25,
			maxWidth: '60%',
			marginTop: theme.spacing(2),
			color: props.hasBackground
				? theme.palette.getContrastText(theme.palette.secondary.main)
				: theme.palette.text.primary,
			[theme.breakpoints.down('xs')]: {
				fontSize: 15,
				marginLeft: theme.spacing(3),
			},
		}),
		headerLogo: {
			maxWidth: 250,
			maxHeight: 150,
			[theme.breakpoints.down('xs')]: {
				maxHeight: 80,
				maxWidth: 120,
			},
		},
		linksContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			flexBasis: '100%',
			justifyContent: 'flex-end',
			alignItems: 'center',
			marginBottom: theme.spacing(3),
			[theme.breakpoints.down('xs')]: {
				justifyContent: 'center',
			},
		},
		headerLink: {
			fontWeight: 600,
			fontSize: 12,
			textDecoration: 'none',
			color: theme.palette.getContrastText(theme.palette.secondary.main),
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
			'&::after': {
				content: "'|'",
				margin: theme.spacing(0, 1),
			},
			'&:last-child::after': {
				content: 'none',
			},
		},
	})
);
