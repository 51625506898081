import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useRecurrentInputsStyle = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .MuiToggleButton-root': {
				color: theme.palette.text.primary,
			},
			'& .MuiToggleButton-root.Mui-selected': {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.getContrastText(theme.palette.secondary.main),
			},
		},
		title: {
			fontSize: 20,
			textAlign: 'center',
			marginBottom: theme.spacing(4),
			fontWeight: 'bold',
		},
		subtitle: {
			fontSize: 18,
			marginBottom: theme.spacing(1),
			fontWeight: 'bold',
		},
		recurrence: {
			margin: theme.spacing(3, 1),
		},
		inputContainer: {
			margin: theme.spacing(3, 1),
		},
		frequency: {
			margin: theme.spacing(2, 1),
		},
		frequencyDetails: {
			margin: theme.spacing(1, 0),
			color: theme.palette.text.secondary,
		},
		input: {
			width: '100%',
			'& input': {
				paddingLeft: theme.spacing(2),
				fontSize: theme.typography.fontSize,
			},
			'& input[type=number]': {
				'-moz-appearance': 'textfield',
			},
			'& input[type=number]::-webkit-outer-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0,
			},
			'& input[type=number]::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0,
			},
		},
		paymentAmountWarning: {
			textAlign: 'center',
			color: theme.palette.warning.main,
			fontWeight: 'bold',
			fontSize: '16px',
		},
		label: {
			paddingLeft: theme.spacing(1),
			fontSize: '1.1em',
		},
		currencyLabel: {
			fontWeight: 700,
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: 35,
			[theme.breakpoints.down('xs')]: {
				fontSize: 30,
			},
		},
		amount: {
			marginBottom: theme.spacing(4),
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		amountTitle: {
			margin: theme.spacing(1, 1),
		},
		customAmount: {
			margin: theme.spacing(2, 1),
		},
		buttons: {
			margin: theme.spacing(4, 1),
			display: 'flex',
			justifyContent: 'space-between',
			'& button': {
				width: 'auto',
			},
		},
		otherAmountInput: {
			borderRadius: 10,

			'& input': {
				fontSize: 20,
				fontWeight: 700,
				padding: `${theme.spacing(2) - 4}px ${theme.spacing(2)}px`,
				[theme.breakpoints.down('xs')]: {
					fontSize: 22,
					padding: `${theme.spacing(2) - 4}px ${theme.spacing(6)}px ${theme.spacing(2) - 4}px ${theme.spacing(1)}px`,
					textAlign: 'center',
				},
			},
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 2),
			},
		},
		backButton: {
			textTransform: 'none',
			color: theme.palette.secondary.main,
			border: 'none',
			boxShadow: 'none',

			'&:hover': {
				boxShadow: 'none',
			},
		},
	})
);
