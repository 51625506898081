import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useIntl } from 'react-intl';

import confirmationImage from '../../../theme/assets/images/payment_confirmation.svg';
import { PaymentLayout } from '../Payment/PaymentLayout';
import { usePaymentFeedbackStyles } from '../PaymentFeedback/styles';

export const PaymentInitiated = (): JSX.Element => {
	const intl = useIntl();

	const styles = usePaymentFeedbackStyles();

	const processingMessage = intl.formatMessage({ id: 'payment-initiated__notify-message' });
	const title = intl.formatMessage({ id: 'payment-initiated__title' });

	return (
		<PaymentLayout>
			<Box className={styles.root}>
				<img className={styles.image} src={confirmationImage} alt="Payment initiated" />
				<h1 color="primary" className={styles.subtitle}>
					{title}
				</h1>
				<Divider flexItem className={styles.divider} />
				<Typography variant="body2" color="primary" align="center" className={styles.additionalMessage}>
					{processingMessage}
				</Typography>
			</Box>
		</PaymentLayout>
	);
};
