import React, { PropsWithChildren, useMemo, useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { CustomError, ErrorType } from 'components/common';
import { ApiError } from 'helpers';
import { updateVersion } from 'helpers/updateVersion';
import { useFetcher, useQueryParams } from 'hooks';
import { useThemeState } from 'theme/ThemeWrapper';

import { version as uiVersion } from '../../../../package.json';
import { getConfigForPayment } from '../Payment/actions/getConfigForPayment';
import { roundToPrecision } from '../Payment/helpers';
import { DEFAULT_QUICK_PAY_CONFIG } from '../QuickPay/constants';
import { DropdownInput } from '../QuickPay/types/types';
import { fetchQuickPayConfig } from './actions/quickPay';
import { saveInitialParameters } from './actions/saveInitialParameters';
import { ConfigurationContext } from './ConfigurationContext';
import { DRAFT_ROUTE, INPUTS_TYPES, QUICK_PAY_ROUTE, SUMMARY_MAPPINGS } from './constants';
import {
	createInitialParametersSession,
	getInitialParametersFromSession,
	mapPaymentParameters,
	getQueryParams,
	mapQuickPayParameters,
	returnPaymentFromConfig,
	emptyConfigInputs,
	urlHasNoParams,
} from './helpers';
import {
	ConfigurationProviderPropTypes,
	ConfigurationWithPaymentData,
	InitialParameters,
	RecurrentPrice,
} from './types';

const url = window.location.pathname;
const isQuickPayPage = url.includes(QUICK_PAY_ROUTE);
const isDraft = url.includes(DRAFT_ROUTE);

export const ConfigurationProvider = ({
	children,
	fallback,
}: PropsWithChildren<ConfigurationProviderPropTypes>): JSX.Element => {
	const queryParams = useQueryParams();
	const { goBack, replace } = useHistory();
	const intl = useIntl();
	const { setThemePalette } = useThemeState();
	const mappedQueryParameters = mapPaymentParameters(queryParams);
	const sessionData = getInitialParametersFromSession<InitialParameters>();

	const onReturn = () => {
		const returnUrl = paymentConfig && paymentConfig.data.parameters.returnUrl;
		if (returnUrl) {
			window.location.replace(returnUrl);
			return;
		}
		goBack();
	};

	const saveInitialParametersAction = useMemo(() => {
		if (!isQuickPayPage) {
			const extendedUrl = window.location.href.replace(/(%20)+&/g, '&');
			const additionalParameters = { extendedUrl };
			if (mappedQueryParameters.paymentIntentClientSecret && sessionData) {
				return getConfigForPayment(sessionData.clientID, sessionData.entity || '');
			} else if (mappedQueryParameters.payments?.length) {
				createInitialParametersSession({ ...mappedQueryParameters, ...additionalParameters });
			} else if (sessionData) {
				return saveInitialParameters(sessionData);
			}

			return saveInitialParameters({ ...mappedQueryParameters, ...additionalParameters });
		}
		// disabled since we only need the initial query params
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isQuickPayPage && !mappedQueryParameters.paymentIntentClientSecret) {
			replace('/');
		}
	}, [replace, mappedQueryParameters.paymentIntentClientSecret]);

	// Quick Pay items bellow
	const fetchQuickpayConfigAction = useMemo(() => {
		if (isQuickPayPage) {
			const queryParams = { ...getQueryParams() };
			if (queryParams && queryParams.clientId) {
				return fetchQuickPayConfig(queryParams);
			}
		}
	}, []);

	const [quickPayConfig, isLoadingQuickPay, quickPayError] = useFetcher<ConfigurationWithPaymentData>(
		fetchQuickpayConfigAction,
		DEFAULT_QUICK_PAY_CONFIG
	);

	const setInputValue = (
		configurationData: ConfigurationWithPaymentData,
		inputId: string,
		value: string | number
	): void => {
		if (configurationData.config.inputs) {
			let defaultFee: number | undefined = configurationData.defaultFee;
			let isQuantityChange = false;
			let hideQuantityInput = false;
			let newPaymentsConfigInputs = configurationData.config.inputs.map((configInput) => {
				if (configInput.id === inputId) {
					configInput.value = value;

					if (configInput.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE) {
						const revenueCode = configInput.selectionValues?.find((selectionValue) => selectionValue.key === value);
						isQuantityChange = true;

						if (revenueCode?.defaultFee && !revenueCode?.hasLineQuantity) {
							defaultFee = revenueCode.defaultFee;
							hideQuantityInput = true;
						} else if (revenueCode?.hasLineQuantity) {
							defaultFee = revenueCode.defaultFee;
						} else {
							defaultFee = undefined;
						}
					}

					if (configInput.summaryMapping === SUMMARY_MAPPINGS.QUANTITY) {
						isQuantityChange = true;
					}
				}
				return configInput;
			});

			if (isQuantityChange) {
				const quantity = hideQuantityInput
					? 1
					: configurationData.config.inputs.find((input) => input.summaryMapping === SUMMARY_MAPPINGS.QUANTITY)?.value;

				const amountIndex = configurationData.config.inputs.findIndex((input) => input.type === INPUTS_TYPES.CURRENCY);

				newPaymentsConfigInputs = [
					...newPaymentsConfigInputs.slice(0, amountIndex),
					{
						...newPaymentsConfigInputs[amountIndex],
						value: defaultFee && quantity ? roundToPrecision(defaultFee * Number(quantity)) : undefined,
					},
					...newPaymentsConfigInputs.slice(amountIndex + 1),
				];
			}

			const newPaymentConfig = {
				...configurationData,
				config: {
					...configurationData.config,
					inputs: newPaymentsConfigInputs,
				},
				defaultFee: hideQuantityInput ? undefined : defaultFee,
				hasLineQuantity: !!defaultFee && !hideQuantityInput,
			};
			setQuickPayConfigurationData(newPaymentConfig);
		}
	};

	const setInputSelectionValues = (
		configurationData: ConfigurationWithPaymentData,
		inputId: string,
		selectionValues: DropdownInput[]
	): void => {
		const newPaymentsConfigInputs = configurationData.config.inputs?.map((configInput) => {
			if (configInput.id === inputId) {
				return {
					...configInput,
					selectionValues: selectionValues,
				};
			}
			return configInput;
		});

		const newPaymentConfig = {
			...configurationData,
			config: {
				...configurationData.config,
				inputs: newPaymentsConfigInputs,
			},
		};

		setQuickPayConfigurationData(newPaymentConfig);
	};

	const savePaymentToContext = (
		configurationData: ConfigurationWithPaymentData,
		recurrentPrices?: RecurrentPrice[]
	) => {
		const mappedQuickPayParameters = returnPaymentFromConfig(configurationData.config, recurrentPrices);
		const newSavedPayments = [...configurationData.savedPayments, mappedQuickPayParameters];
		newSavedPayments.forEach((payment) => {
			payment.referenceNumber = payment.referenceNumber.trim();
		});
		const newQuickPayConfiguration = {
			...configurationData,
			config: configurationData.config,
			savedPayments: newSavedPayments,
		};
		setQuickPayConfigurationData(newQuickPayConfiguration);
		emptyConfigInputs(configurationData.config);
	};

	const removePaymentFromContext = (configurationData: ConfigurationWithPaymentData, paymentId: string) => {
		const newSavedPayments = configurationData.savedPayments.filter((payment) => payment.id !== paymentId);
		const newQuickPayConfiguration = {
			...configurationData,
			savedPayments: newSavedPayments,
		};
		setQuickPayConfigurationData(newQuickPayConfiguration);
	};

	const setQuickPayCurrentPage = (config: ConfigurationWithPaymentData, currentPage: string) => {
		if (isQuickPayPage) {
			const newConfig = { ...config, quickPayCurrentPage: currentPage };
			setQuickPayConfigurationData(newConfig);
		}
	};

	const [xPayConfigurationData, setXPayConfigurationData] = useState<ConfigurationWithPaymentData>(
		{} as ConfigurationWithPaymentData
	);

	const updateIdentifier = useCallback((newIdentifier: string) => {
		if (isQuickPayPage) {
			setQuickPayConfigurationData((prevQuickPayConfigurationData) => ({
				...prevQuickPayConfigurationData,
				data: {
					...prevQuickPayConfigurationData.data,
					identifier: newIdentifier,
				},
			}));
		} else {
			setXPayConfigurationData((prevXPayConfigurationData) => ({
				...prevXPayConfigurationData,
				data: {
					...prevXPayConfigurationData.data,
					identifier: newIdentifier,
				},
			}));
		}
	}, []);

	const [quickPayConfigurationData, setQuickPayConfigurationData] = useState<ConfigurationWithPaymentData>({
		config: quickPayConfig.config,
		setInputValue: setInputValue,
		setInputDropdownValues: setInputSelectionValues,
		appVersion: quickPayConfig.appVersion,
		data: quickPayConfig.data,
		isQuickPayPage: true,
		isDraft,
		hasLineQuantity: quickPayConfig.hasLineQuantity,
		defaultFee: quickPayConfig.defaultFee,
		quickPayCurrentPage: 'inputs',
		setQuickPayCurrentPage,
		savedPayments: [],
		savePayment: savePaymentToContext,
		removePayment: removePaymentFromContext,
		updateIdentifier,
	});

	const saveQuickPayParametersAction = useMemo(() => {
		if (isQuickPayPage && quickPayConfigurationData.quickPayCurrentPage === 'summary') {
			const mappedQuickPayParameters = mapQuickPayParameters(
				quickPayConfigurationData.config,
				quickPayConfigurationData.savedPayments
			);
			const sessionData = getInitialParametersFromSession<InitialParameters>();

			const extendedUrl = sessionData && sessionData?.extendedUrl ? { extendedUrl: sessionData?.extendedUrl } : {};
			const sessionId = sessionData && sessionData?.sessionId ? { sessionId: sessionData?.sessionId } : {};

			if (mappedQuickPayParameters.payments?.length) {
				createInitialParametersSession({ ...mappedQuickPayParameters, ...extendedUrl, ...sessionId });
			} else if (sessionData) {
				return saveInitialParameters(sessionData);
			}

			return saveInitialParameters({ ...mappedQuickPayParameters, ...extendedUrl, ...sessionId });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quickPayConfigurationData.config, quickPayConfigurationData.quickPayCurrentPage]);

	useEffect(() => {
		if (isQuickPayPage) {
			const noParamsInUrl = urlHasNoParams();
			const sessionData = getInitialParametersFromSession<InitialParameters>();

			if (noParamsInUrl) {
				if (sessionData && sessionData.extendedUrl && sessionData.extendedUrl.includes('client')) {
					window.location.href = sessionData?.extendedUrl;
				}
			}
			const queryParams = { ...getQueryParams() };

			createInitialParametersSession({
				...queryParams,
				extendedUrl: window.location.href,
				am: roundToPrecision(Number(queryParams.am), 2),
			});

			if (!queryParams.paymentIntentClientSecret) {
				replace(QUICK_PAY_ROUTE);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [paymentConfig, isLoading, error] = useFetcher<ConfigurationWithPaymentData | null>(
		isQuickPayPage ? saveQuickPayParametersAction : saveInitialParametersAction,
		null
	);

	useEffect(() => {
		if (isQuickPayPage) {
			setQuickPayConfigurationData({
				setInputValue: setInputValue,
				setQuickPayConfig: setQuickPayConfigurationData,
				setInputDropdownValues: setInputSelectionValues,
				config: quickPayConfig.config,
				appVersion: quickPayConfig.appVersion,
				data: quickPayConfig.data,
				isQuickPayPage: true,
				isDraft,
				hasLineQuantity: quickPayConfig.hasLineQuantity,
				defaultFee: quickPayConfig.defaultFee,
				quickPayCurrentPage: 'inputs',
				setQuickPayCurrentPage,
				savedPayments: [],
				savePayment: savePaymentToContext,
				removePayment: removePaymentFromContext,
				updateIdentifier,
			});
		}
	}, [quickPayConfig, updateIdentifier]);

	useEffect(() => {
		if (paymentConfig) {
			if (isQuickPayPage) {
				setQuickPayConfigurationData({
					...quickPayConfigurationData,
					data: paymentConfig.data,
				});
			} else {
				setXPayConfigurationData({
					...xPayConfigurationData,
					data: paymentConfig.data,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentConfig]);
	// quick pay items above

	useEffect(() => {
		if (uiVersion && paymentConfig?.appVersion) {
			updateVersion(uiVersion, paymentConfig?.appVersion);
		}
	}, [paymentConfig]);

	const { palette, pageTitle, favicon } = isQuickPayPage
		? (quickPayConfig && quickPayConfig.config.branding) || { palette: null }
		: (paymentConfig && paymentConfig.config.branding) || { palette: null };

	useEffect(() => {
		if (palette) {
			setThemePalette(palette);
		}
	}, [palette, setThemePalette, paymentConfig]);

	useEffect(() => {
		if (pageTitle && favicon) {
			const faviconElement = document.getElementById('favicon') as HTMLLinkElement | null;
			document.title = pageTitle;
			if (faviconElement) {
				faviconElement.href = favicon;
			}
		}
	}, [pageTitle, favicon]);

	if (isQuickPayPage && quickPayConfig.config.clientID && !isLoadingQuickPay) {
		return <ConfigurationContext.Provider value={quickPayConfigurationData}>{children}</ConfigurationContext.Provider>;
	} else if (paymentConfig && !isLoading) {
		if (mappedQueryParameters.paymentIntentClientSecret && sessionData) {
			const config = {
				...paymentConfig,
				...xPayConfigurationData,
				isQuickPayPage: false,
				isDraft,
				data: { identifier: '', parameters: sessionData },
				appVersion: 'unknown',
				savedPayments: [],
				savePayment: savePaymentToContext,
				removePayment: removePaymentFromContext,
				updateIdentifier,
			};
			return <ConfigurationContext.Provider value={{ ...config }}>{children}</ConfigurationContext.Provider>;
		}
		return (
			<ConfigurationContext.Provider value={{ ...paymentConfig, ...xPayConfigurationData, isDraft, updateIdentifier }}>
				{children}
			</ConfigurationContext.Provider>
		);
	} else if (error || quickPayError) {
		const existingError = error || quickPayError;

		let genericErrorMessage = intl.formatMessage({ id: 'generic-error__message' });
		let genericErrorTitle = intl.formatMessage({ id: 'generic-error__title' });
		let genericErrorMessageSupport = intl.formatMessage({ id: 'generic-error__support-text' });

		if (existingError) {
			switch (((existingError as unknown) as ApiError).type) {
				case ErrorType.XPAY_HASH_INTEGRITY_FAILED:
					genericErrorMessage = intl.formatMessage({ id: 'hash-integrity-error__message' });
					genericErrorTitle = intl.formatMessage({ id: 'hash-integrity-error__title' });
					break;
				case ErrorType.XPAY_INVALID_VALUE:
					genericErrorMessage = intl.formatMessage({ id: 'invalid-param-value-error__message' });
					genericErrorTitle = intl.formatMessage({ id: 'invalid-param-value-error__title' });
					break;
				case ErrorType.MISSING_CONFIGURATION:
					genericErrorMessage = intl.formatMessage({ id: 'invalid-configuration-error__message' });
					genericErrorTitle = intl.formatMessage({ id: 'invalid-configuration-error__title' });
					break;
				case ErrorType.UNIQUE_ID:
					genericErrorMessage = intl.formatMessage({ id: 'link-already-used-error__message' });
					genericErrorTitle = intl.formatMessage({ id: 'link-already-used-error__title' });
					genericErrorMessageSupport = intl.formatMessage({ id: 'link-already-used-error__support' });
					break;
				default:
					break;
			}
		}

		return (
			<CustomError
				messages={[genericErrorMessage, genericErrorMessageSupport]}
				title={genericErrorTitle}
				onReturn={onReturn}
				hideReturnButton={
					paymentConfig?.config.payByLinkConfiguration?.isPayByLink ||
					((existingError as unknown) as ApiError).type === ErrorType.UNIQUE_ID
				}
			/>
		);
	}
	return <>{fallback}</>;
};
