import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import confirmationImage from '../../../theme/assets/images/payment_confirmation.svg';
import { usePaymentParameters, useGetIsQuickPayPage, useGetIsPayByLink } from '../Configuration/hooks';
import { PaymentLayout } from '../Payment/PaymentLayout';
import { usePaymentFeedbackStyles } from './styles';

export const PaymentProcessing: React.FunctionComponent = () => {
	const history = useHistory();
	const { returnUrl, extendedUrl } = usePaymentParameters();
	const isPayByLink = useGetIsPayByLink();
	const intl = useIntl();
	const styles = usePaymentFeedbackStyles();
	const isQuickPayPage = useGetIsQuickPayPage();

	const processingMessage = intl.formatMessage({ id: 'payment-processing__notify-message' });
	const title = intl.formatMessage({ id: 'payment-processing__title' });
	const buttonText = intl.formatMessage({ id: 'button__home' });

	const onReturn = () => {
		if (isQuickPayPage) {
			window.location.replace('/payments');
			return;
		}

		if (returnUrl) {
			window.location.replace(returnUrl);
			return;
		}

		if (extendedUrl) {
			window.location.replace(extendedUrl);
			return;
		}

		history.goBack();
	};

	return (
		<PaymentLayout>
			<Box className={styles.root}>
				<img className={styles.image} src={confirmationImage} alt="Payment confirmation" />
				<h1 color="primary" className={styles.subtitle}>
					{title}
				</h1>
				<Divider flexItem className={styles.divider} />
				<Typography variant="body2" color="primary" align="center" className={styles.additionalMessage}>
					{processingMessage}
				</Typography>
				{!isPayByLink && (
					<Button
						variant="contained"
						color="secondary"
						startIcon={<Icon>home</Icon>}
						onClick={onReturn}
						size="large"
						className={styles.returnBtn}
						aria-label={buttonText}
					>
						{buttonText}
					</Button>
				)}
			</Box>
		</PaymentLayout>
	);
};
