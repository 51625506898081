import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import errorImage from '../../../theme/assets/images/error.svg';
import { useCustomErrorStyles } from './styles';
import { CustomErrorPropTypes } from './types';

// needed so 'label' attribute can be added on Button
declare module 'react' {
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		label?: string;
	}
}

export const CustomError: FunctionComponent<CustomErrorPropTypes> = ({
	messages,
	title,
	onReturn,
	hideReturnButton = false,
}) => {
	const styles = useCustomErrorStyles();
	const intl = useIntl();

	const buttonText = intl.formatMessage({ id: 'button__home' });

	return (
		<Box className={styles.root} component="main">
			<img className={styles.image} src={errorImage} alt="Man holding an error sign" />
			<Typography variant="h1" color="primary" className={styles.subtitle} align="center">
				{title}
			</Typography>
			{messages.map((message, index) => (
				<Typography variant="body2" color="primary" align="center" className={styles.additionalMessage} key={index}>
					{message}
				</Typography>
			))}

			{!hideReturnButton && (
				<Button
					variant="contained"
					color="secondary"
					onClick={onReturn}
					size="large"
					className={styles.returnBtn}
					aria-label={buttonText}
				>
					{buttonText}
				</Button>
			)}
		</Box>
	);
};
