import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useAmountStyles = makeStyles((theme: Theme) =>
	createStyles({
		amountText: {
			fontSize: theme.typography.fontSize,
			fontWeight: 700,
			paddingRight: theme.spacing(1) / 2,
			verticalAlign: 'middle',
			[theme.breakpoints.down('xs')]: {
				fontSize: 12,
			},
		},
	})
);
