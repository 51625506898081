import { Modal } from '@material-ui/core';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import React, { useMemo } from 'react';

import { Loading } from 'components/common';
import { useFetcher } from 'hooks';

import { useClientBranding } from '../Configuration/hooks';
import { PaymentConfirmation } from '../PaymentFeedback/PaymentConfirmation';
import { PaymentError } from '../PaymentFeedback/PaymentError';
import { PaymentProcessing } from '../PaymentFeedback/PaymentProcessing';
import { useStripePaymentStyles } from './styles';
import { StripePaymentStatus } from './types/paymentSystem';

export const StripeBankPaymentConfirmation: React.FunctionComponent<{
	paymentIntentClientSecret: string;
}> = ({ paymentIntentClientSecret }) => {
	const stripe = useStripe();
	const {
		header,
		footer: { hasBackgroundColor: footerHasBackground },
	} = useClientBranding();
	const { hasBackgroundColor: headerHasBackround } = header;
	const classes = useStripePaymentStyles({
		headerHasBackground: headerHasBackround,
		footerHasBackground: footerHasBackground,
	});

	const getPaymentIntentData = useMemo(() => {
		if (stripe) {
			return () => {
				return stripe.retrievePaymentIntent(paymentIntentClientSecret);
			};
		}
	}, [paymentIntentClientSecret, stripe]);

	const [paymentIntentData, isLoading] = useFetcher<PaymentIntentResult | undefined>(getPaymentIntentData, undefined);

	if (paymentIntentData && !isLoading) {
		const { paymentIntent } = paymentIntentData;
		if (paymentIntent) {
			switch (paymentIntent.status) {
				case StripePaymentStatus.SUCCEEDED:
					return (
						<Modal open>
							<PaymentConfirmation />
						</Modal>
					);

				case StripePaymentStatus.REQUIRES_PAYMENT_METHOD:
					return (
						<Modal open>
							<PaymentError />
						</Modal>
					);

				default:
					return (
						<Modal open>
							<PaymentProcessing />
						</Modal>
					);
			}
		}
	}
	return (
		<Modal open>
			<div className={classes.loadingOverlay}>
				<Loading />
			</div>
		</Modal>
	);
};
