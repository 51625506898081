import { Backdrop } from '@material-ui/core';
import { common, grey } from '@material-ui/core/colors';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { withStyles } from '@material-ui/styles';

const midScreenHeight = 706;

export const useStripePaymentStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
			backgroundColor: theme.palette.background.paper,
			maxWidth: '100%',
		},

		paymentHeader: {
			textAlign: 'center',
		},

		paymentHeaderContainer: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			justifyContent: 'center',
		},

		paymentHeaderTitle: (props: { headerHasBackground?: boolean; footerHasBackground?: boolean }) => ({
			fontWeight: 700,
			paddingBottom: '8px',
			marginLeft: theme.spacing(4),
			textAlign: 'left',
			maxWidth: '60%',
			marginTop: theme.spacing(2),
			fontSize: '1.5rem',
			lineBreak: 'anywhere',
			color: props.headerHasBackground
				? theme.palette.getContrastText(theme.palette.secondary.main)
				: theme.palette.text.primary,
			[theme.breakpoints.down('xs')]: {
				fontSize: 15,
				marginLeft: theme.spacing(3),
			},
		}),

		paymentHeaderDetails: {
			fontSize: '20px',
			color: theme.palette.primary.light,
		},

		paymentForm: {
			margin: '-0px 0',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('xs')]: {
				justifyContent: 'space-between',
			},
		},

		paymentFormTitle: {
			fontSize: '16px',
			margin: theme.spacing(4, 0, 1, 0),
			lineHeight: ' 2.2',
			fontWeight: 'bold',
		},

		paymentFormContent: {
			alignSelf: 'center',
			width: '330px',
			maxWidth: '100%',
		},

		stripeInputWrapper: {
			display: 'inline-block',
			width: ' 100%',
			padding: '5px 10px 10px 10px',
			position: 'relative',
			[theme.breakpoints.down('xs')]: {
				padding: '5px 10px 5px 10px',
			},
			'& .StripeElement': {
				padding: '8px',
				border: '1px solid rgba(33, 48, 66, 0.1)',
				boxShadow: 'rgba(50, 50, 93, 0.14902) 1px 1px 4px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
				borderRadius: '8px',
				margin: '3px 10px',
				transition: ' box-shadow 0.3s, border 0.3s',
				backgroundColor: common.white,
				width: '100%',
				'&:-webkit-autofill': {
					background: theme.palette.primary.dark,
				},
			},

			'& .StripeElement--invalid': {
				borderColor: theme.palette.error.main,
			},
		},

		amount: {
			fontSize: '30px',
			lineHeight: '0.9',
			marginBottom: theme.spacing(2),
			fontWeight: 700,
			textAlign: 'center',
			color: theme.palette.text.primary,
			[theme.breakpoints.down('xs')]: {
				marginBottom: theme.spacing(1),
			},
			[`@media (max-height: ${midScreenHeight}px)`]: {
				marginBottom: theme.spacing(1),
			},
			'&:before, &:after': {
				content: 'attr(data-prefix)',
				display: 'inline-block',
				paddingRight: '8px',
				color: theme.palette.text.primary,
			},
			'&:after': {
				content: 'attr(data-suffix)',
				paddingRight: 0,
				paddingLeft: '8px',
			},
			'&.inverted': {
				color: theme.palette.primary.dark,
				'&:after,	&:before': {
					color: theme.palette.primary.dark,
				},
			},
		},

		paymentStripeInput: {
			padding: '0 8px 8px 8px',
			border: '1px solid rgba(33, 48, 66, 0.1)',
			boxShadow: 'rgba(50, 50, 93, 0.14902) 1px 1px 4px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
			borderRadius: '2px',
			margin: '10px',
			transition: ' box-shadow 0.3s, border 0.3s',
			backgroundColor: theme.palette.primary.light,
			width: 'auto',
			'&:-webkit-autofill': {
				background: theme.palette.primary.dark,
			},
		},
		stripeInputLabel: {
			fontWeight: 'bold',
			paddingLeft: theme.spacing(1),
			fontSize: theme.typography.fontSize,
		},
		loadingOverlay: {
			position: 'fixed',
			width: '100vw',
			zIndex: 99,
			background: 'rgba(255, 255, 255, 0.7)',
			height: '100vh',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		},

		hasCustomIcon: {
			'	&::before': {
				position: 'absolute',
				bottom: '31px',
				left: '28px',
				height: '15px',
				width: '15px',
			},
			'& > .StripeElement': {
				paddingLeft: '30px',
			},
			'& > .stripe-input': {
				paddingLeft: '30px',
			},
		},
		stripeInput: {
			border: '1px solid rgba(33, 48, 66, 0.1)',
			boxShadow: 'rgba(50, 50, 93, 0.14902) 1px 1px 4px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
			borderRadius: '8px',
			margin: '3px 10px',
			transition: 'box-shadow 0.3s, border 0.3s',
			backgroundColor: common.white,
			'&:-webkit-autofill': {
				background: common.white,
			},
			padding: '8px 8px 8px 30px',
			fontSize: '18px',
			outline: 'none',
			width: '100%',
			color: common.black,
			'&::placeholder': {
				fontFamily: 'inherit',
				color: grey,
				opacity: 1,
			},
			'&:focus': {
				boxShadow: 'rgba(50, 50, 93, 0.14902) 2px 1px 9px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
			},
		},

		stripeInputError: {
			borderColor: theme.palette.error.main,
		},
		paymentCardFeeWarning: {
			textAlign: 'center',
			color: theme.palette.warning.main,
			fontWeight: 'bold',
			fontSize: '16px',
		},
		paymentModalButtons: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},

		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minWidth: '600px',
			[theme.breakpoints.down('xs')]: {
				minWidth: '100%',
			},
		},

		modalContent: {
			background: common.white,
			maxHeight: ' calc(93vh - 35px)',
			justifyContent: 'space-between',
			minHeight: '300px',
			maxWidth: '650px',
			width: '80%',
			borderRadius: '20px',
			boxShadow: theme.palette.primary.main,
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up('sm')]: {
				width: 550,
			},
			[theme.breakpoints.up('lg')]: {
				width: 650,
			},
			[theme.breakpoints.down('xs')]: {
				minHeight: '80%',
				width: '95%',
			},
			[`@media (max-height: ${midScreenHeight}px)`]: {
				minHeight: '95%',
			},
		},

		modalHeader: (props: { headerHasBackground?: boolean }) => ({
			display: 'flex',
			overflow: 'hidden',
			flexDirection: 'column',
			padding: '4% 4% 2% 4%',
			backgroundColor: props.headerHasBackground ? theme.palette.secondary.main : 'transparent',
			borderRadius: '20px 20px 0 0',
			borderBottom: !props.headerHasBackground ? `1px solid ${theme.palette.divider}` : undefined,
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
			[`@media (max-height: ${midScreenHeight}px)`]: {
				padding: '2% 0',
			},
		}),
		modalBody: {
			padding: theme.spacing(4, 1),
			[theme.breakpoints.up('xs')]: {
				padding: theme.spacing(4, 2),
			},
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(6),
				paddingTop: theme.spacing(4),
			},
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(4, 2),
				flexGrow: 2,
			},
			[`@media (max-height: ${midScreenHeight}px)`]: {
				marginBottom: theme.spacing(2, 1),
			},
			display: 'flex',
			overflowY: 'auto',
		},

		captchaContainer: {
			padding: theme.spacing(4, 1),
			[theme.breakpoints.up('xs')]: {
				padding: theme.spacing(4, 2),
			},
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(6),
				paddingTop: theme.spacing(4),
			},
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(4, 2),
				flexGrow: 2,
			},
			[`@media (max-height: ${midScreenHeight}px)`]: {
				marginBottom: theme.spacing(2, 1),
			},
			display: 'flex',
			justifyContent: 'center',
			overflowY: 'auto',
		},

		captchaWrapper: {
			margin: '-0px 0',
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('xs')]: {
				justifyContent: 'space-between',
			},
		},

		modalFooter: (props: { footerHasBackground?: boolean }) => ({
			display: 'flex',
			flexDirection: 'column',
			padding: '25px',
			borderTop: `1px solid ${theme.palette.primary.light}`,
			background: props.footerHasBackground ? theme.palette.secondary.main : theme.palette.primary.dark,
			borderRadius: '0px 0px 20px 20px',
			[theme.breakpoints.down('sm')]: {
				padding: '3%',
			},
		}),

		error: {
			color: theme.palette.error.main,
			alignSelf: 'center',
			fontSize: '1em',
		},

		button: (props: { footerHasBackground?: boolean }) => ({
			background: props.footerHasBackground ? theme.palette.secondary.contrastText : theme.palette.primary.light,
			display: 'flex',
			color: props.footerHasBackground ? theme.palette.secondary.main : common.white,
			alignItems: 'center',
			justifyContent: 'center',
			boxShadow: 'rgba(50, 50, 93, 0.14902) 2px 1px 9px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
			height: 'min(45px, 4.5vh)',
			fontSize: '15px',
			padding: '0 45px',
			margin: '0px 20px',
			borderRadius: '8px',
			'&:disabled': {
				color: 'rgba(0, 0, 0, 0.87)',
				opacity: '0.4',
				cursor: 'not-allowed',
			},
			'	&:hover': {
				background: props.footerHasBackground ? theme.palette.secondary.contrastText : theme.palette.primary.light,
			},
			'&:focus': {
				outline: theme.palette.primary.main,
			},
			'& svg': {
				verticalAlign: 'middle',
				width: 'min(30px, 3vh)',
				height: 'min(22px, 2.2vh)',
				marginRight: '12px',
				marginBottom: '2px',
			},
			[theme.breakpoints.down('sm')]: {
				margin: '10px 0px',
				padding: '3%',
			},
		}),
		modalContentHead: {
			display: 'flex',
			flexDirection: 'column',
			flexShrink: 0,
			alignSelf: 'center',
			width: '330px',
			maxWidth: '100%',
			[theme.breakpoints.down('xs')]: {
				flexGrow: 2,
				justifyContent: 'center',
			},
		},

		headerLogo: {
			maxWidth: 250,
			maxHeight: 150,
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
			[`@media (max-height: ${midScreenHeight}px)`]: {
				display: 'none',
			},
		},
		buttonProgress: {
			marginLeft: theme.spacing(1) - 2,
			animation: 'none',
		},
		errorTitle: {
			alignSelf: 'center',
			fontSize: 18,
			fontWeight: 400,
			color: theme.palette.error.main,
		},
		errorMessage: {
			alignSelf: 'center',
			fontSize: 14,
			color: theme.palette.error.main,
		},
	})
);
export const usePaymentModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			zIndex: theme.zIndex.drawer + 1,
			backgroundColor: alpha(theme.palette.common.black, 0.85),
		},
		paymentInfo: {
			color: theme.palette.common.white,
		},
	})
);

export const usePaymentLayoutStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.custom
				? theme.palette.custom.main
				: theme.palette.primary[(700 as unknown) as keyof PaletteColor],
			alignItems: 'center',
			maxWidth: '100%',
			flex: '1 0 auto',
		},
		container: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: 15,
			width: '80%',
			maxWidth: 650,
			margin: theme.spacing(6),
			flex: 1,
			[theme.breakpoints.up('sm')]: {
				width: 550,
			},
			[theme.breakpoints.up('lg')]: {
				width: 650,
			},
			[theme.breakpoints.down('xs')]: {
				width: '95%',
			},
		},
	})
);

export const StyledBackdrop = withStyles({
	root: {
		zIndex: 1,
		color: common.white,
		padding: '0 20px',
	},
})(Backdrop);
