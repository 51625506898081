import Container from '@material-ui/core/Container';
import React from 'react';

import { RouterWrapper } from 'components/common';
import { routes } from 'routes/routes';

import { useAppStyles } from './styles';

export const AppWrapper = () => {
	const styles = useAppStyles();

	return (
		<Container disableGutters maxWidth={false} classes={{ root: styles.root }}>
			<RouterWrapper routes={routes} />
		</Container>
	);
};
