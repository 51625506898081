import {
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
	Table as MUITable,
	TableHead,
	TableFooter,
} from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

import { useTableStyles } from './styles';
import { TablePropTypes } from './types';

export const Table = <GenericData,>({
	data,
	columns,
	modifier,
	shouldHideTableHeader,
	footer,
	caption,
	stickyHeader = true,
}: PropsWithChildren<TablePropTypes<GenericData>>) => {
	const styles = useTableStyles();

	const formattedData = modifier ? data.map(modifier) : data;
	return (
		<>
			<TableContainer className={styles.root}>
				<MUITable className={styles.table} stickyHeader={stickyHeader} tabIndex={0}>
					{/* the row bellow is used only for accesability */}
					{caption && <caption style={{ visibility: 'hidden' }}>{caption}</caption>}
					{!shouldHideTableHeader && (
						<TableHead>
							<TableRow className={styles.tableRow}>
								{columns.map((column) => (
									<TableCell align={column.align} key={`column-${String(column.id)}`} {...column.additionalCellProps}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{formattedData.map((row, index) => {
							return (
								<TableRow key={index} className={styles.tableRow}>
									{columns.map((column, cellIndex) => {
										return (
											<TableCell key={cellIndex} {...column.additionalCellProps} align={column.align || 'left'}>
												{row[column.id]}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
					{footer && (
						<TableFooter>
							<TableRow className={styles.tableRow}>
								{columns.map((column) => (
									<TableCell align={column.align} key={`footer-${String(column.id)}`} {...column.additionalCellProps}>
										<strong>{footer[column.id]}</strong>
									</TableCell>
								))}
							</TableRow>
						</TableFooter>
					)}
				</MUITable>
			</TableContainer>
		</>
	);
};
