import { createTheme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import createPalette from '@material-ui/core/styles/createPalette';

import { Palette } from 'theme/ThemeWrapper';

import { customiseThemePalette } from './themes';

declare module '@material-ui/core/styles/createBreakpoints' {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		'mobile-sm': true;
	}
}

export const customTheme = (paletteOptions: Palette | null) => {
	const theme = customiseThemePalette(paletteOptions);

	const paletteUpdatedTeme = {
		...theme,
		palette: {
			...theme.palette,
			warning: { ...theme.palette.warning, main: '#A86500' },
		},
	};

	const palette = createPalette(theme.palette);

	return createTheme({
		...paletteUpdatedTeme,
		breakpoints: {
			values: {
				'mobile-sm': 0,
				xs: 360,
				sm: 640,
				md: 960,
				lg: 1280,
				xl: 1920,
			},
		},
		shape: {
			borderRadius: 8,
		},
		typography: {
			fontFamily: [
				'Mulish',
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		transitions: {
			duration: {
				standard: 300,
				enteringScreen: 300,
				leavingScreen: 200,
			},
		},
		overrides: {
			MuiTablePagination: {
				root: {
					flexShrink: 0,
					boxShadow: `0 -1px 2px 0 ${palette.divider}`,
				},
			},
			MuiTableCell: {
				head: {
					fontWeight: 700,
					whiteSpace: 'nowrap',
				},
			},
			MuiSvgIcon: {
				root: {
					verticalAlign: 'baseline',
				},
			},
			MuiButton: {
				root: {
					fontWeight: 700,
					minWidth: '90px',
				},
				containedSecondary: {
					'&:hover': {
						backgroundColor: darken(palette.secondary.main, 0.1),
					},
				},
			},
			MuiTab: {
				root: {
					padding: '20px 12px',
				},
				wrapper: {
					fontSize: '14px',
					fontWeight: 700,
					textTransform: 'none',
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
					fontSize: '14px',
				},
			},
			MuiOutlinedInput: {
				input: {
					fontSize: '14px',
				},
			},
			MuiInputLabel: {
				root: {
					fontSize: '14px',
				},
			},
			MuiFormControlLabel: {
				label: {
					fontSize: '14px',
				},
			},
			MuiInputBase: {
				root: {
					'&$disabled': {
						color: '#525252',
						backgroundColor: '#F7F5F6',
					},
				},
			},
		},
	});
};
