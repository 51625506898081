import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export const useCustomErrorStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '100%',
			flex: 1,
			backgroundColor: theme.palette.primary[(700 as unknown) as keyof PaletteColor],
			padding: theme.spacing(4, 2),
			[theme.breakpoints.up('xs')]: {
				padding: theme.spacing(6),
			},
		},
		image: {
			maxWidth: 350,
			height: 'auto',
			width: '70%',
			margin: theme.spacing(5, 0, 6),
			[theme.breakpoints.up('xs')]: {
				width: '50%',
			},
		},
		subtitle: {
			padding: theme.spacing(0, 6),
			color: theme.palette.common.white,
			fontWeight: 700,
			fontSize: 24,
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(0),
				marginBottom: theme.spacing(2),
				fontSize: 20,
			},
		},
		additionalMessage: {
			margin: theme.spacing(2, 0, 4),
			color: theme.palette.grey[400],
			lineHeight: '20px',
			[theme.breakpoints.down('xs')]: {
				fontSize: 12,
			},
			'&:first-of-type:not(:only-of-type)': {
				margin: theme.spacing(2, 0, 0),
			},
			'&:last-of-type:not(:only-of-type)': {
				margin: theme.spacing(0, 0, 6),
			},
		},
		returnBtn: {
			width: '50%',
			textTransform: 'none',
			marginBottom: theme.spacing(3),
			fontSize: 16,
			maxWidth: 200,
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				padding: `${theme.spacing(1) - 2}px ${theme.spacing(2)}px`,
				maxWidth: '100%',
				fontSize: 18,
			},
		},
	})
);
