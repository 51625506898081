import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import errorImage from '../../../theme/assets/images/error.svg';
import { usePaymentParameters, useGetIsQuickPayPage, useGetIsPayByLink } from '../Configuration/hooks';
import { PaymentLayout } from '../Payment/PaymentLayout';
import { usePaymentFeedbackStyles } from './styles';

export const PaymentError: React.FunctionComponent<{ declined?: boolean; sessionExpired?: boolean }> = ({
	declined = false,
	sessionExpired = false,
}) => {
	const history = useHistory();
	const { returnUrl, extendedUrl } = usePaymentParameters();
	const isPayByLink = useGetIsPayByLink();
	const intl = useIntl();
	const styles = usePaymentFeedbackStyles();
	const isQuickPayPage = useGetIsQuickPayPage();

	let title = intl.formatMessage({ id: 'generic-error__title' });
	let message = intl.formatMessage({ id: 'payment-error__message' });

	if (sessionExpired) {
		title = intl.formatMessage({ id: 'payment-session-expired__title' });
		message = intl.formatMessage({ id: 'payment-session-expired__message' });
	} else if (declined) {
		title = intl.formatMessage({ id: 'payment-cancellation__title' });
		message = intl.formatMessage({ id: 'payment-cancellation__message' });
	}

	const buttonText = intl.formatMessage({ id: 'button__home' });

	const onReturn = () => {
		if (isQuickPayPage) {
			window.location.replace('/payments');
			return;
		}

		if (returnUrl) {
			window.location.replace(returnUrl);
			return;
		}

		if (extendedUrl) {
			window.location.replace(extendedUrl);
			return;
		}

		history.goBack();
	};

	return (
		<PaymentLayout>
			<Box className={styles.root}>
				<img className={styles.image} src={errorImage} alt="Man holding an error sign" />
				<h1 color="primary" className={styles.subtitle}>
					{title}
				</h1>
				<Divider flexItem className={styles.divider} />
				<Typography variant="body2" color="primary" align="center" className={styles.additionalMessage}>
					{message}
				</Typography>
				{!isPayByLink && (
					<Button
						variant="contained"
						color="secondary"
						startIcon={<Icon>home</Icon>}
						onClick={onReturn}
						size="large"
						className={styles.returnBtn}
						aria-label={buttonText}
					>
						{buttonText}
					</Button>
				)}
			</Box>
		</PaymentLayout>
	);
};
