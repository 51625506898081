import { Divider } from '@material-ui/core';
import React, { FunctionComponent, useRef } from 'react';

import { Amount } from 'components/common';
import { SinglePaymentItemTable } from 'components/common/Table/SinglePaymentItemTable';
import { useClientBranding, usePaymentParameters } from 'components/features/Configuration/hooks';
import { InitialQueryPayment } from 'components/features/Configuration/types';

import { usePaymentContext } from '../../PaymentContext';
import { SummaryItem } from '../constants';
import { mapPaymentParameterLabels } from '../helpers';

export const SinglePaymentTable: FunctionComponent = () => {
	const { paymentSummaryLabelMappings } = useClientBranding();
	const customParamsLabelMapping = mapPaymentParameterLabels(paymentSummaryLabelMappings || {});
	const paymentParameters = usePaymentParameters();
	const { paymentData } = usePaymentContext();
	const initialBalance = useRef(paymentData.amount);

	const items = Object.entries(customParamsLabelMapping).reduce((data, current) => {
		const [key, label] = current;
		const value = paymentParameters?.payments?.[0][key as keyof InitialQueryPayment] as string;
		if (key === 'amount') {
			return [
				...data,
				{
					name: label as string,
					value: <Amount balance={initialBalance.current} />,
				},
			];
		}
		return [...data, { name: label as string, value }];
	}, [] as SummaryItem[]);
	return (
		<>
			<Divider />
			<SinglePaymentItemTable data={items} />
		</>
	);
};
