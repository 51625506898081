import { fetchWrapper, RequestMethod } from 'helpers';

import { PaymentSessionConfirmation } from '../types/payment';
import { PaymentSession } from '../types/paymentSystem';

export const confirmPaymentSession = (paymentId: string, body: PaymentSession) => async (): Promise<
	PaymentSessionConfirmation
> =>
	fetchWrapper<PaymentSessionConfirmation>(`/payments/${paymentId}/confirm`, {
		method: RequestMethod.POST,
		body,
	});
