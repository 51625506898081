import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export const useQuickPayLayoutStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.custom
				? theme.palette.custom.main
				: theme.palette.primary[(700 as unknown) as keyof PaletteColor],
			alignItems: 'center',
			maxWidth: '100%',
			flex: '1 0 auto',
		},
		container: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: 15,
			width: '80%',
			maxWidth: 650,
			margin: theme.spacing(6),
			flex: 1,
			[theme.breakpoints.up('sm')]: {
				width: 550,
			},
			[theme.breakpoints.up('lg')]: {
				width: 650,
			},
			[theme.breakpoints.down('xs')]: {
				width: '95%',
			},
		},
	})
);

export const useQuickPayPageStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: theme.spacing(4, 1),
			[theme.breakpoints.up('xs')]: {
				padding: theme.spacing(4, 2),
			},
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(6),
			},
		},
		errorRoot: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
			backgroundColor: theme.palette.background.paper,
			maxWidth: '100%',
		},
		inputsContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(2),
		},
		pageTitle: {
			fontWeight: 700,
			fontSize: 24,
			[theme.breakpoints.down('xs')]: {
				paddingLeft: theme.spacing(2),
				padding: theme.spacing(0),
				fontSize: 20,
			},
			'&.withMarginBottom': {
				marginBottom: theme.spacing(6),
			},
		},
		inputTemplate: {
			marginBottom: theme.spacing(0.3),
		},
		inputHelper: {
			paddingLeft: theme.spacing(1),
			fontSize: '1em',
		},
		label: {
			fontWeight: 'bold',
			paddingLeft: theme.spacing(1),
			fontSize: '1.1em',
		},
		errorMessage: {
			fontSize: '1em',
			color: '#ae0000',
			paddingLeft: theme.spacing(1),
			visibility: 'hidden',
			minHeight: '22.5px',

			'&.visible': {
				visibility: 'visible',
			},
		},
		select: {
			'& .MuiOutlinedInput-notchedOutline': {
				padding: theme.spacing(0, 4),
				fontSize: 20,
			},
		},
		currencyLabel: {
			fontWeight: 700,
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: 48,
			[theme.breakpoints.down('xs')]: {
				fontSize: 35,
			},
		},
		balanceContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		generalInput: {
			'& input': {
				paddingLeft: theme.spacing(2),
				fontSize: theme.typography.fontSize,
			},
			'& input[type=number]': {
				'-moz-appearance': 'textfield',
			},
			'& input[type=number]::-webkit-outer-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0,
			},
			'& input[type=number]::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0,
			},
		},
		contentHelperText: {
			padding: theme.spacing(0, 6),
			color: '#333333',
			marginTop: theme.spacing(1) - 3,
			marginBottom: theme.spacing(4),
			fontSize: '1em',
			[theme.breakpoints.down('xs')]: {
				paddingLeft: theme.spacing(2),
			},
		},
		amountInput: {
			maxWidth: '100%',
			width: '90%',
			boxShadow: theme.shadows[3],
			borderRadius: 10,
			margin: theme.spacing(0, 2),

			'& input': {
				fontSize: 20,
				fontWeight: 700,
				padding: `${theme.spacing(2) - 4}px ${theme.spacing(2)}px`,
				[theme.breakpoints.down('xs')]: {
					fontSize: 22,
					padding: `${theme.spacing(2) - 4}px ${theme.spacing(6)}px ${theme.spacing(2) - 4}px ${theme.spacing(1)}px`,
					textAlign: 'center',
				},
			},
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 2),
			},
		},
		buttonsContainer: {
			display: 'flex',
			justifyContent: 'space-between',

			'&.flexEnd': {
				justifyContent: 'flex-end',
			},
		},
		cartButtonContainer: {
			display: 'flex',
		},
		backButton: {
			textTransform: 'none',
			color: theme.palette.secondary.main,
			border: 'none',
			boxShadow: 'none',

			'&:hover': {
				boxShadow: 'none',
			},
		},
		buttonProgress: {
			marginLeft: theme.spacing(1) - 2,
			color: 'white',
		},
		marginLeft: {
			marginLeft: theme.spacing(1),
		},
		hidden: {
			display: 'none',
		},
	})
);

export const useAddressFormStyles = makeStyles((theme: Theme) =>
	createStyles({
		addressContainer: {
			width: '100%',
		},
		addressInput: {
			width: '100%',
			fontSize: theme.typography.fontSize,
			marginBottom: theme.spacing(0.5),
		},
		postalCode: {
			width: '50%',
			minWidth: 100,
			fontSize: theme.typography.fontSize,
		},
		formLabel: {
			fontWeight: 'bold',
			paddingLeft: theme.spacing(1),
			fontSize: '1.1em',
		},
		addressLabel: {
			paddingLeft: theme.spacing(1),
			fontSize: '1em',
		},
		select: {
			'& $outlined': {
				display: 'flex',
				alignItems: 'center',
				height: 50,
				padding: theme.spacing(0, 6),
			},
			'& .MuiOutlinedInput-notchedOutline': {
				padding: theme.spacing(0, 4),
				fontSize: 20,
			},
		},
		addressError: {
			marginTop: theme.spacing(1) - 3,
			alignSelf: 'flex-start',
			marginBottom: theme.spacing(1) - 3,
			fontSize: '0.7em',
			visibility: 'hidden',
			[theme.breakpoints.up('sm')]: {
				fontSize: '1em',
			},
			color: theme.palette.error.main,
		},
		visible: {
			visibility: 'visible',
		},
	})
);

export const useSummaryPageStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
			backgroundColor: theme.palette.background.paper,
			maxWidth: '100%',
		},
		generalInput: {
			width: '100%',
			fontSize: theme.typography.fontSize,
		},
		emailContainer: {
			marginTop: theme.spacing(4),
			margin: theme.spacing(4, 0),
		},
		emailLabel: {
			fontSize: '0.7em',
			paddingLeft: theme.spacing(2),
			marginBottom: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				paddingLeft: theme.spacing(6),
				fontSize: '1em',
			},
		},
		openBankingWarning: {
			textAlign: 'center',
			color: theme.palette.warning.main,
		},
		emailHelper: {
			marginTop: theme.spacing(1) - 3,
			color: 'black',
			alignSelf: 'flex-start',
			marginBottom: theme.spacing(1) - 3,
			padding: `${theme.spacing(0)}px ${theme.spacing(2)}px `,
			fontSize: '0.7em',
			[theme.breakpoints.up('sm')]: {
				padding: `${theme.spacing(0)}px ${theme.spacing(6)}px `,
				fontSize: '1em',
			},
		},
		emailErrorColor: {
			color: theme.palette.error.main,
		},
		amountInput: {
			width: '90%',
			boxShadow: theme.shadows[3],
			borderRadius: 10,
			margin: theme.spacing(4, 2),
			'& input': {
				fontSize: 20,
				fontWeight: 700,
				padding: `${theme.spacing(2) - 4}px ${theme.spacing(2)}px`,
				[theme.breakpoints.down('xs')]: {
					fontSize: 22,
					padding: `${theme.spacing(2) - 4}px ${theme.spacing(6)}px ${theme.spacing(2) - 4}px ${theme.spacing(1)}px`,
					textAlign: 'center',
				},
			},
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				margin: theme.spacing(0, 0, 2),
			},
		},
		balanceContainer: {
			display: 'flex',
			width: '100%',
			justifyContent: 'flex-end',
			alignItems: 'center',
			margin: theme.spacing(2, 0),
			[theme.breakpoints.down('xs')]: {
				flexDirection: 'column',
				alignItems: 'stretch',
				justifyContent: 'flex-start',
			},
		},
		payBtn: {
			textTransform: 'none',
			fontSize: 18,
			[theme.breakpoints.down('xs')]: {
				width: '100%',
			},
		},
		currencyLabel: {
			fontWeight: 700,
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: 48,
			[theme.breakpoints.down('xs')]: {
				fontSize: 35,
			},
		},
		buttonProgress: {
			marginLeft: theme.spacing(1) - 2,
		},
		paymentTerms: {
			width: '100%',
			marginTop: theme.spacing(5),
			display: 'flex',
			flexDirection: 'column',
		},
		paymentTerm: {
			fontSize: 12,
			lineHeight: '1.2',
			alignSelf: 'flex-start',
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
		},
		errorMessage: {
			alignSelf: 'center',
			fontSize: 14,
			color: theme.palette.error.main,
		},
	})
);

export const useShoppingCartStyles = makeStyles((theme: Theme) =>
	createStyles({
		modalContainer: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: 15,
			width: '50%',
			height: 200,
			maxWidth: 550,
			margin: theme.spacing(6),
			flex: 1,
			[theme.breakpoints.up('sm')]: {
				width: 550,
			},
			[theme.breakpoints.down('xs')]: {
				width: '95%',
			},
		},
		shoppingCartContainer: {
			display: 'flex',
			flexDirection: 'row-reverse',
			height: 20,
		},
		cartButton: {
			textTransform: 'none',
			width: 35,
			height: 35,
			color: theme.palette.secondary.main,
			minWidth: 'auto',
			paddig: 3,
			borderRadius: '50%',
			boxShadow: 'none',

			'&.disabled': {
				pointerEvents: 'none',
			},
		},
		totalPrice: {
			fontSize: '1.3em',
			paddingLeft: theme.spacing(6),
			fontWeight: 'bold',
		},
		buttonsContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
		},
		emptyCartMessage: {
			fontWeight: 'bold',
			fontSize: '2em',
			padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
		},
		deleteButton: {
			backgroundColor: 'white',
			border: 'none',
			outline: 'none',
			cursor: 'pointer',
		},
		cart: {
			width: '60%',
			[theme.breakpoints.down('sm')]: {
				width: '80%',
			},
			[theme.breakpoints.down('xs')]: {
				width: '100%',
			},
		},
	})
);
