import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import { formatNumber } from 'components/features/Payment/helpers';
import { useConfig } from 'components/features/QuickPay/hooks';

import { useAmountStyles } from './styles';

export interface AmountPropTypes {
	balance: number;
}

export const Amount: FunctionComponent<AmountPropTypes> = ({ balance }) => {
	const styles = useAmountStyles();
	const {
		amountFormat,
		paymentSystem: { currency, currencySymbol },
	} = useConfig();

	const numberFormatParts = new Intl.NumberFormat(undefined, { currency, style: 'currency' }).formatToParts();
	const symbol = currencySymbol || numberFormatParts.find((part) => part.type === 'currency')?.value || '';

	return (
		<Typography variant="caption" color="initial" className={styles.amountText}>
			{`${symbol}${
				amountFormat && Object.keys(amountFormat).length > 0
					? formatNumber(balance, amountFormat)
					: balance.toLocaleString(undefined, {
							currency,
							style: 'decimal',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
					  })
			}`}
		</Typography>
	);
};
