import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const usePaymentFeedbackStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			minWidth: 300,
			padding: theme.spacing(4, 3),
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(6),
			},
		},
		image: {
			height: 'auto',
			width: '65%',
			maxHeight: 230,
			margin: theme.spacing(3, 0, 4),
			[theme.breakpoints.up('xs')]: {
				width: '50%',
			},
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(5, 0, 6),
			},
		},
		subtitle: {
			marginBottom: theme.spacing(2),
			fontSize: '1.8em',
			padding: theme.spacing(0, 6),
			fontWeight: 700,
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(0),
				marginBottom: theme.spacing(2),
				fontSize: 20,
			},
		},
		divider: {
			minHeight: 1,
		},
		additionalMessage: {
			margin: theme.spacing(2, 0, 4),
			textAlign: 'center',
			[theme.breakpoints.down('xs')]: {
				fontSize: 12,
			},
		},
		returnBtn: {
			width: '50%',
			textTransform: 'none',
			marginBottom: theme.spacing(3),
			fontSize: 20,
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				padding: theme.spacing(1) - 2,
				fontSize: 18,
			},
		},
		receiptBtn: {
			width: '50%',
			textTransform: 'none',
			marginBottom: theme.spacing(3),
			fontSize: 20,
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				padding: theme.spacing(1) - 2,
				fontSize: 18,
			},
		},
		buttonProgress: {
			marginLeft: theme.spacing(1) - 2,
			animation: 'none',
			color: 'white',
		},
		printReceiptMessage: {
			color: theme.palette.warning.main,
		},
	})
);
