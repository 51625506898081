import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { useLoadingStyles } from './styles';

export const Loading: FunctionComponent = () => {
	const styles = useLoadingStyles();
	return (
		<div className={styles.root}>
			<div className={styles.spinner}>
				<div className={styles.cube}></div>
				<div className={classnames(styles.cube, styles.secondCube)}></div>
			</div>
		</div>
	);
};
