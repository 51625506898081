import { RouteProps } from 'components/common';
import { PaymentInitiated } from 'components/features/Payment/PaymentInitiated';
import { lazy } from 'helpers';

export const HOME_ROUTE = '/';
export const QUICK_PAY_ROUTE = '/payments';

const PaymentSummary = lazy(() => import('components/features'), 'PaymentSummary');
const QuickPayPage = lazy(() => import('components/features/QuickPay/QuickPayPage'), 'QuickPayPage');

export const routes: Array<RouteProps> = [
	{
		path: QUICK_PAY_ROUTE,
		exact: true,
		isPrivate: true,
		component: QuickPayPage,
		permissions: ['level1'],
	},
	{
		path: HOME_ROUTE,
		exact: true,
		isPrivate: true,
		component: PaymentSummary,
		permissions: ['level1'],
	},
	{
		path: '/initiated',
		exact: true,
		isPrivate: true,
		component: PaymentInitiated,
		permissions: ['level1'],
	},
];
