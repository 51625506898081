import { ReactNode } from 'react';

import { PaymentSystemType } from '../Payment/types/paymentSystem';
import {
	AddToCartButton,
	CheckoutButton,
	Input,
	Summary,
	QuickPayExternalValidation,
	DropdownInput,
} from '../QuickPay/types/types';

interface PaymentMethods {
	card: boolean;
	wallets: boolean;
	openBanking: boolean;
	stripelink: boolean;
}
export interface PaymentSystemBaseConfiguration {
	type: PaymentSystemType;
	currency: string;
	currencySymbol?: string;
	declineOnCancel?: boolean;
	initiatePaymentUrl?: string;
	paymentMethods?: PaymentMethods;
	areas?: string;
	tills?: string;
}

export interface AmountFormat {
	format: string;
	description: string;
	length?: number;
	trailingCharacter: string;
	hasDecimalSeparator: boolean;
	decimalSeparator?: string;
	hasDecimals: boolean;
	hasThousandsSeparator: boolean;
	thousandsSeparator?: string;
	isSigned: boolean;
}
export interface PayByLinkConfiguration {
	isPayByLink?: boolean;
}

export interface Configuration<T extends PaymentSystemBaseConfiguration = PaymentSystemBaseConfiguration> {
	clientID: string;
	entity?: string;
	paymentSystem: T;
	id: string;
	createdAt: string;
	updatedAt: string;
	branding: ClientBranding;
	paymentGroups?: QuickPayPaymentGroup[];
	inputs?: Input[];
	summary?: Summary[];
	externalValidation?: QuickPayExternalValidation;
	backUrl?: string;
	amountValidation?: AmountValidation;
	isEmailMandatory?: boolean;
	isEmailUneditable?: boolean;
	area?: string;
	till?: string;
	amountFormat?: AmountFormat;
	basicInfo: {
		country: string;
		postalCode: string;
	};
	payByLinkConfiguration?: PayByLinkConfiguration;
	recurrentPrices?: RecurrentPrice[];
}

export interface ConfigurationProviderPropTypes {
	fallback?: ReactNode;
}

export interface PaymentSavedInputs {
	summaryMapping: string;
	value: string;
}

export interface InitialQueryPayment {
	id?: string;
	paymentInputs?: PaymentSavedInputs[];
	revenueCode: string;
	referenceNumber: string;
	referenceNumberDescription: string;
	additionalInformation?: string;
	additionalInformationDescription?: string;
	amount: number;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	recurrenceData?: {
		selectedAmount: number;
		selectedFrequency: string;
		selectedRecurrence: string;
		isCustomAmount?: boolean;
		price: string;
		format: {
			interval: string;
			intervalCount: number;
		};
	};
}

export interface InitialQueryParameters {
	paymentIntentClientSecret?: string;
	clientID: string;
	area?: string;
	till?: string;
	entity?: string;
	co?: string;
	bu?: string;
	lang?: string;
	mode?: string;
	comment?: string;
	externalReference?: string;
	returnUrl?: string;
	backUrl?: string;
	email?: string;
	ccEmail?: string;
	sessionId?: string;
	hash?: string;
	payments?: InitialQueryPayment[];
	identifier?: string;
}

export interface InitialParameters extends InitialQueryParameters {
	extendedUrl?: string;
}

export enum ExternalURLPaymentMappings {
	pmttyp = 'revenueCode',
	val1 = 'referenceNumber',
	val1desc = 'referenceNumberDescription',
	val2 = 'additionalInformation',
	val2desc = 'additionalInformationDescription',
	am = 'amount',
	cat1 = 'category1',
	cat2 = 'category2',
	cat3 = 'category3',
	cat4 = 'category4',
	cat5 = 'category5',
	cat6 = 'category6',
	cat7 = 'category7',
}

export enum ExternalURLParamsMappings {
	client = 'clientID',
	area = 'area',
	till = 'till',
	entity = 'entity',
	co = 'co',
	bu = 'bu',
	lang = 'lang',
	mode = 'mode',
	cmt = 'comment',
	extref = 'externalReference',
	rurl = 'returnUrl',
	burl = 'backUrl',
	email = 'email',
	ccemail = 'ccEmail',
	sid = 'sessionId',
	hash = 'hash',
	payment_intent_client_secret = 'paymentIntentClientSecret',
}

interface BackButton {
	text: string;
}

export interface ClientBranding {
	logo: string;
	palette?: {
		main: string;
		secondary?: string;
	};
	paymentTerms?: string[];
	paymentSummaryLabelMappings?: { [key: string]: string }; // must match param names from external URL
	header: {
		logoOnly: boolean;
		links?: {
			label: string;
			link: string;
		}[];
		text?: string;
		hasBackgroundColor: boolean;
	};
	footer: {
		links?: {
			label: string;
			link: string;
		}[];
		logo?: string;
		text?: string[] | string;
		hasBackgroundColor: boolean;
	};
	pageTitle?: string;
	favicon?: string;
	helperText?: string;
	contentTitle?: string;
	showPrintReceipt?: boolean;
	contentHelperText?: string;
	addToCartButton?: AddToCartButton;
	checkoutButton?: CheckoutButton;
	backButton?: BackButton;
}

export interface AmountValidation {
	minValue: number;
	message?: string;
}

export interface QuickPayPaymentGroup {
	name: string;
	selectionValues?: DropdownInput[];
}

export interface ExtraChargeRevenueCode {
	revenueCode: string;
	fee: number;
}
export interface RecurrentPrice {
	id: string;
	amount: string;
	revenueCode: string;
	unitAmount: number;
	product: string;
	isCustomAmount: boolean;
	isCustomerChoosesPrice: boolean;
	customAmountFormat?: null | {
		preset: number;
		maximum: number;
		minimum: number;
	};
	recurrenceOptions: {
		priceId: string;
		name: string;
		format: {
			interval: string;
			intervalCount: number;
		};
		fixedBillingDate?: {
			day: number;
			month: number;
		};
	}[];
}

export interface ConfigurationWithPaymentData<
	T extends PaymentSystemBaseConfiguration = PaymentSystemBaseConfiguration
> {
	config: Configuration<T>;
	appVersion: string;
	data: {
		identifier: string;
		parameters: InitialParameters;
		openBankingOnly?: string;
		extraChargeRevenueCode?: ExtraChargeRevenueCode;
		recurrentPrices?: RecurrentPrice[];
	};
	isQuickPayPage: boolean;
	isDraft: boolean;
	setInputValue?: (configurationData: ConfigurationWithPaymentData, inputId: string, value: string | number) => void;
	setQuickPayConfig?: (configurationData: ConfigurationWithPaymentData) => void;
	setInputDropdownValues?: (
		configurationData: ConfigurationWithPaymentData,
		inputId: string,
		selectionValues: DropdownInput[]
	) => void;
	quickPayCurrentPage?: string;
	setQuickPayCurrentPage?: (config: ConfigurationWithPaymentData, currentPage: string) => void;
	savedPayments: InitialQueryPayment[];
	savePayment: (configurationData: ConfigurationWithPaymentData, prices?: RecurrentPrice[]) => void;
	removePayment: (configurationData: ConfigurationWithPaymentData, paymentId: string) => void;
	hasLineQuantity?: boolean;
	defaultFee?: number;
	updateIdentifier: (newIdentifier: string) => void;
}
