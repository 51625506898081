import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useTimerStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderBottom: `1px solid ${theme.palette.divider}`,
			'&.danger': {
				color: theme.palette.error.main,
			},
		},
	})
);
