import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useAppStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			root: {
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				overflow: 'auto',
				backgroundColor: theme.palette.background.paper,
				maxWidth: '100%',
			},
		}),
	{ index: 1 }
);
