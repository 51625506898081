import { makeStyles, createStyles, Theme, alpha } from '@material-ui/core/styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export const useFooterStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: (props: { hasBackground?: boolean }) => ({
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: props.hasBackground ? theme.palette.secondary.main : 'transparent',
			padding: theme.spacing(4, 6),
			minHeight: 150,
			borderTop: '1px solid transparent',
			borderColor: !props.hasBackground ? alpha(theme.palette.secondary.main, 0.2) : undefined,
			[theme.breakpoints.down('xs')]: {
				padding: theme.spacing(4, 1),
				flexDirection: 'column',
			},
		}),
		footerLogo: {
			maxWidth: 250,
			maxHeight: 100,
			marginRight: theme.spacing(6),
			[theme.breakpoints.down('xs')]: {
				maxWidth: 120,
				maxHeight: 80,
				margin: theme.spacing(3, 0),
			},
		},
		textContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		linksContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: theme.spacing(4),
			[theme.breakpoints.down('xs')]: {
				flexDirection: 'column',
			},
		},
		footerLink: (props: { hasBackground?: boolean }) => ({
			fontWeight: 600,
			lineHeight: '1.8',
			textDecoration: 'none',
			color: props.hasBackground
				? theme.palette.getContrastText(theme.palette.secondary.main)
				: theme.palette.custom
				? theme.palette.getContrastText(theme.palette.custom.main)
				: theme.palette.getContrastText(theme.palette.primary[(700 as unknown) as keyof PaletteColor]),
			'&::after': {
				content: "'|'",
				margin: theme.spacing(0, 3),
				[theme.breakpoints.down('xs')]: {
					content: 'none',
				},
			},
			'&:last-child::after': {
				content: 'none',
			},
			'&:hover': {
				textDecoration: 'underline',
			},
		}),
		footerParagraph: (props: { hasBackground?: boolean }) => ({
			fontWeight: 600,
			lineHeight: '1.8',
			color: props.hasBackground
				? theme.palette.getContrastText(theme.palette.secondary.main)
				: theme.palette.custom
				? theme.palette.getContrastText(theme.palette.custom.main)
				: theme.palette.getContrastText(theme.palette.primary[(700 as unknown) as keyof PaletteColor]),
		}),
	})
);
