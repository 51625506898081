import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';

import { useConfiguration } from '../Configuration/hooks';
import { StripeBankPaymentConfirmation } from './StripeBankPaymentConfirmation';
import { StripePaymentSystem } from './types/paymentSystem';

export const StripeBankPaymentConfirmationWrapper = ({
	paymentIntentClientSecret,
}: {
	paymentIntentClientSecret: string;
}) => {
	const configuration = useConfiguration<StripePaymentSystem>();
	const [stripePromise] = useState(() =>
		loadStripe(configuration.config.paymentSystem.publicKey, {
			betas: ['blocked_card_brands_beta_2'],
		})
	);
	return (
		<Elements stripe={stripePromise} options={{ clientSecret: paymentIntentClientSecret }}>
			<StripeBankPaymentConfirmation paymentIntentClientSecret={paymentIntentClientSecret} />
		</Elements>
	);
};
