import { TableContainer, TableRow, TableCell, TableBody, Table as MUITable } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

import { useTableStyles } from './styles';
import { SinglePaymentTablePropTypes } from './types';

export const SinglePaymentItemTable = ({ data }: PropsWithChildren<SinglePaymentTablePropTypes>) => {
	const styles = useTableStyles();

	return (
		<>
			<TableContainer className={styles.root}>
				<MUITable className={styles.table} stickyHeader tabIndex={0}>
					<TableBody>
						{data.map((element) => (
							<TableRow key={element.name}>
								<TableCell variant="head" className={styles.singleItemHead}>
									{element.name}
								</TableCell>
								<TableCell align="right">{element.value || ''}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</MUITable>
			</TableContainer>
		</>
	);
};
