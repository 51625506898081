import { PaymentSystemType } from '../Payment/types/paymentSystem';
import { Configuration, PaymentSystemBaseConfiguration, ClientBranding } from './types';

export const DEFAULT_PAYMENT_SYSTEM: PaymentSystemBaseConfiguration = {
	type: PaymentSystemType.MASTERCARD,
	currency: 'GBP',
};

export const DEFAULT_CONFIG: Configuration = {
	clientID: '',
	id: '',
	basicInfo: {
		country: 'UK',
		postalCode: 'BS207AN',
	},
	createdAt: '',
	updatedAt: '',
	paymentSystem: DEFAULT_PAYMENT_SYSTEM,
	branding: {} as ClientBranding,
};

export const QUICK_PAY_ROUTE = '/payments';
export const DRAFT_ROUTE = '/draft';

export const INITIAL_PARAMETERS_SESSION_KEY = 'INITIAL_PARAMS';
export const INITIAL_PARAMETERS_EXPIRE_TIME = 1800000;

export const SUMMARY_MAPPINGS = {
	REVENUE_CODE: 'revenueCode',
	REFERENCE_NUMBER: 'referenceNumber',
	AMOUNT: 'amount',
	PRICE: 'price',
	QUANTITY: 'quantity',
	ADDITIONAL_INFORMATION: 'additionalInformation',
};

export const INPUTS_TYPES = {
	DROPDOWN: 'dropdown',
	CURRENCY: 'currency',
	NUMBER: 'number',
	STRING: 'string',
	ADDRESS: 'address',
	PAYMENT_GROUP: 'paymentGroup',
};
