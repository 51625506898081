import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { RouteProps } from './types';

export const RouterWrapper: React.FC<{ routes: Array<RouteProps> }> = ({ routes }) => (
	<Switch>
		{routes.map(({ path, component, ...rest }) => (
			<Route key={path} path={path} component={component} {...rest} />
		))}
	</Switch>
);
