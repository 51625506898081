import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classnames from 'classnames';
import React, { useState, FunctionComponent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import {
	usePaymentParameters,
	useClientBranding,
	useAmountValidation,
	useIsEmailMandatory,
	useConfiguration,
	useIsEmailUneditable,
} from 'components/features/Configuration/hooks';
import { MAX_ALLOWED_BALANCE } from 'components/features/QuickPay/constants';
import { useAmountSeparators } from 'components/features/QuickPay/hooks';
import { validatePaymentAmount } from 'helpers/validatePaymentAmount';

import { getCurrentDate, calculatePaymentsTotalAmount } from '../helpers';
import { PaymentData } from '../types/payment';
import { validateEmailFormat } from './helpers';
import { usePaymentDetailsStyles } from './styles';
import { SummaryTable } from './SummaryTable/SummaryTable';

export const PaymentDetails: FunctionComponent<{ onNext: (data: PaymentData) => void; loading?: boolean }> = ({
	onNext,
	loading,
}) => {
	const {
		data: {
			parameters: { payments },
		},
		config: {
			paymentSystem: { currency, currencySymbol },
		},
		data: { openBankingOnly, identifier },
	} = useConfiguration();

	const [isEmailErrorVisible, setEmailErrorVisibility] = useState(false);
	const paymentParameters = usePaymentParameters();
	const isEmailMandatory = useIsEmailMandatory();
	const isEmailUneditable = useIsEmailUneditable();
	const amountValidation = useAmountValidation();
	const { paymentTerms, backButton } = useClientBranding();
	const styles = usePaymentDetailsStyles();
	const matchesMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const [email, setEmail] = useState(paymentParameters.email || '');
	const [amountError, setAmountError] = useState(false);
	const intl = useIntl();
	const isInvalidAmount = useMemo(
		() => amountValidation?.minValue && !validatePaymentAmount(paymentParameters.payments, amountValidation?.minValue),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[paymentParameters.payments, amountValidation?.minValue]
	);

	const { thousandsSeparator, decimalSeparator } = useAmountSeparators();

	const balance = calculatePaymentsTotalAmount(payments);
	const numberFormatParts = new Intl.NumberFormat(undefined, { currency, style: 'currency' }).formatToParts();
	const symbol = currencySymbol || numberFormatParts.find((part) => part.type === 'currency')?.value || '';

	const revenueCodeDescription = openBankingOnly
		? paymentParameters.payments?.find((paymentParam) => paymentParam.revenueCode === openBankingOnly)
				?.referenceNumberDescription
		: '';

	const emailHelperText = isEmailMandatory
		? intl.formatMessage({ id: 'payment-email__mandatory-helperText' })
		: intl.formatMessage({ id: 'payment-email__helperText' });
	const payButtonText = intl.formatMessage({ id: 'button__pay' });
	const summaryTitle = intl.formatMessage({ id: 'payment-summary__title' });

	useEffect(() => {
		if ((amountValidation && balance < amountValidation?.minValue) || balance < 0 || isInvalidAmount) {
			setAmountError(true);
		}
	}, [isInvalidAmount, amountValidation, balance]);

	const onPay = () => {
		// just to be safe, in case user manager to enable the pay button from developer tools
		if (balance > MAX_ALLOWED_BALANCE) {
			return;
		}
		const isEmailValid = validateEmailFormat(email);
		if ((isEmailMandatory && !email.length) || (email.length && !isEmailValid)) {
			setEmailErrorVisibility(true);
		} else {
			onNext({ amount: balance, email });
		}
	};

	const handleBack = () => {
		if (paymentParameters.backUrl) {
			window.location.replace(paymentParameters.backUrl);
		}
	};

	const checkIfValid = ({ value }: NumberFormatValues) => (value.startsWith('0') ? value.indexOf('.') === 1 : true);

	const amountErrorMessage = amountValidation?.message
		? amountValidation.message
		: intl.formatMessage({ id: 'amount-validation_error' });

	return (
		<Box className={styles.root}>
			<Typography variant="h1" color="primary" className={styles.subtitle}>
				{summaryTitle}
			</Typography>
			<Typography variant="subtitle1" component="div" className={styles.summaryDate}>
				{getCurrentDate()}
			</Typography>
			<SummaryTable />
			<FormControl variant="outlined" className={styles.emailContainer}>
				<Typography className={styles.emailLabel}>{`Email${isEmailMandatory ? '*' : ' '}`}</Typography>
				<OutlinedInput
					id="email"
					value={email}
					onChange={(evt) => {
						setEmailErrorVisibility(false);
						setEmail(String(evt.target.value).trim());
					}}
					required={isEmailMandatory}
					inputProps={{
						'aria-label': intl.formatMessage({ id: 'payment-email' }),
						...(isEmailMandatory && { 'aria-required': true }),
						autoComplete: 'email',
					}}
					className={styles.emailInput}
					classes={{ notchedOutline: styles.notchedOutline }}
					disabled={Boolean(paymentParameters.email) && isEmailUneditable}
				/>
				{isEmailErrorVisible ? (
					<Typography
						className={classnames(styles.emailHelperText, styles.errorTextColor)}
						role="alert"
						aria-live="polite"
					>
						{email.length
							? intl.formatMessage({ id: 'payment-email__error' })
							: intl.formatMessage({ id: 'payment-email__error_mandatory' })}
					</Typography>
				) : (
					<FormHelperText className={styles.emailHelperText}>{emailHelperText}</FormHelperText>
				)}
			</FormControl>

			{openBankingOnly && (
				<div className={styles.openBankingWarning} id="open-banking">
					{intl.formatMessage({ id: 'stripe-payment__open-banking-only' }, { revenueCodeDescription })}
				</div>
			)}

			<div className={styles.balanceContainer}>
				{!matchesMediaQuery && (
					<label className={styles.currencyLabel} htmlFor="balance-input">
						{symbol}
					</label>
				)}
				<NumberFormat
					value={balance}
					displayType="input"
					{...thousandsSeparator}
					{...decimalSeparator}
					decimalScale={2}
					fixedDecimalScale
					customInput={TextField}
					placeholder="balance"
					name="balance"
					isAllowed={checkIfValid}
					variant="outlined"
					id="balance-input"
					autoComplete="off"
					className={styles.balanceInput}
					readOnly
					disabled
					InputProps={
						matchesMediaQuery
							? {
									startAdornment: (
										<InputAdornment position="start">
											<label className={styles.currencyLabel} htmlFor="balance-input">
												{symbol}
											</label>
										</InputAdornment>
									),
									readOnly: true,
							  }
							: { readOnly: true }
					}
				/>
				<Button
					variant="contained"
					color="secondary"
					id="paymentButton"
					startIcon={<Icon>credit_card</Icon>}
					onClick={onPay}
					disabled={!balance || !identifier || loading || amountError || balance > MAX_ALLOWED_BALANCE}
					aria-disabled={!balance || loading || amountError || balance > MAX_ALLOWED_BALANCE ? true : false}
					size="large"
					className={styles.payBtn}
					aria-label={payButtonText}
					{...(openBankingOnly && { 'aria-describedby': 'open-banking' })}
				>
					{payButtonText}
					{loading && <CircularProgress size={20} className={styles.buttonProgress} />}
				</Button>
			</div>

			{amountError && (
				<p className={styles.errorMessage} aria-live="polite" role="alert">
					{amountErrorMessage}
				</p>
			)}

			{paymentTerms &&
				paymentTerms.map((paragraph, index) => (
					<Typography variant="body2" color="textSecondary" align="left" className={styles.paymentTerm} key={index}>
						{paragraph}
					</Typography>
				))}
			{paymentParameters.backUrl && paymentParameters.backUrl.length && (
				<Button
					size="large"
					className={styles.backButton}
					variant="contained"
					color="primary"
					style={{ backgroundColor: 'transparent' }}
					onClick={handleBack}
					aria-label={backButton ? backButton.text : intl.formatMessage({ id: 'button__back' })}
				>
					{backButton ? backButton.text : intl.formatMessage({ id: 'button__back' })}
				</Button>
			)}
			{balance > MAX_ALLOWED_BALANCE && (
				<Typography aria-live="polite" className={styles.errorMessage} role="alert">
					{intl.formatMessage({ id: 'payment_amount-exceeded-error' })}
				</Typography>
			)}
		</Box>
	);
};
