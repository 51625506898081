import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useTableStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
		table: {
			minWidth: 300,
			borderCollapse: 'collapse',
		},
		tableRow: {
			'& th': {
				backgroundColor: 'transparent',
			},
			'& td, & th': {
				[theme.breakpoints.down('xs')]: {
					fontSize: 11,
				},
			},
			'& td:first-child, & th:first-child': {
				paddingLeft: theme.spacing(6),
			},
			'& td:last-child, & th:last-child': {
				paddingRight: theme.spacing(6),
			},
			[theme.breakpoints.down('xs')]: {
				'& td:first-child, & th:first-child': {
					paddingLeft: theme.spacing(2),
				},
				'& td:last-child, & th:last-child': {
					paddingRight: theme.spacing(2),
				},
			},
		},
		singleItemHead: {
			backgroundColor: 'transparent',
			fontWeight: 'normal',
		},
	})
);
