import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { formatTime, MINUTE } from 'helpers/formatTime';

import { useTimerStyles } from './styles';

interface TimerProps {
	timeLeft?: number;
}

const WARNING_LIMIT = 5 * MINUTE;

export const Timer: FunctionComponent<TimerProps> = ({ timeLeft = 0 }) => {
	const classes = useTimerStyles();
	const intl = useIntl();

	return (
		<div
			className={classNames(classes.container, { danger: timeLeft <= WARNING_LIMIT })}
			tabIndex={0}
			role="timer"
			aria-atomic="true"
		>
			{intl.formatMessage({ id: 'stripe-payment__session-timer' }, { time: formatTime(timeLeft) })}
		</div>
	);
};
