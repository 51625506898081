import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { readConfig, browserHistory, loadGoogleTagManager } from 'helpers';

import App from './App';
import { LangContextProvider } from './state/LangContext';

import './index.css';

readConfig();
if (window.ENV.ANALYTICS_ENABLED) {
	loadGoogleTagManager();
}

ReactDOM.render(
	<Router history={browserHistory}>
		<LangContextProvider>
			<App />
		</LangContextProvider>
	</Router>,
	document.getElementById('root')
);
