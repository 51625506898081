import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { useState, useMemo } from 'react';

import { customTheme } from './styles';

export interface Palette {
	main: string;
	secondary?: string;
}

export const ThemeContext = React.createContext<{ setThemePalette: (options: Palette) => void }>({
	setThemePalette: () => ({}),
});

export const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
	const [paletteOptions, setPaletteOptions] = useState<Palette | null>(null);

	const theme = useMemo(() => customTheme(paletteOptions), [paletteOptions]);

	return (
		<ThemeContext.Provider value={{ setThemePalette: setPaletteOptions }}>
			<ThemeProvider<typeof customTheme> theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useThemeState = () => React.useContext(ThemeContext);
