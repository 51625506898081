import { fetchWrapper, RequestMethod } from 'helpers';

import { PaymentInitiation } from '../types/paymentSystem';

export const initiatePaymentSession = (paymentId: string) => async (): Promise<PaymentInitiation> =>
	fetchWrapper<PaymentInitiation>(`/payments/${paymentId}/initiate`, { method: RequestMethod.POST });

export const initiatePaymentSessionCallback = async (
	paymentId: string,
	selectedPaymentType: string
): Promise<PaymentInitiation> =>
	fetchWrapper<PaymentInitiation>(`/payments/${paymentId}/initiate`, {
		method: RequestMethod.POST,
		body: { selectedPaymentType },
	});
