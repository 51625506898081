import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const usePaymentDetailsStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: theme.spacing(4, 1),
			[theme.breakpoints.up('xs')]: {
				padding: theme.spacing(4, 3),
			},
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(6),
			},
		},
		subtitle: {
			padding: theme.spacing(0, 6),
			fontWeight: 700,
			fontSize: 24,
			[theme.breakpoints.down('xs')]: {
				paddingLeft: theme.spacing(2),
				padding: theme.spacing(0),
				fontSize: 20,
			},
		},
		emailContainer: {
			margin: theme.spacing(4, 0),
		},
		emailInput: {
			'& input': {
				fontSize: 11,
				[theme.breakpoints.up('sm')]: {
					paddingLeft: theme.spacing(6),
					fontSize: theme.typography.fontSize,
				},
			},
			'& $notchedOutline': {
				[theme.breakpoints.up('sm')]: {
					padding: theme.spacing(0, 5),
				},
			},
		},
		recurrentPaymentWrapper: {
			padding: '10px 0px',
			display: 'flex',
			alignItems: 'center',
		},
		emailLabel: {
			fontSize: '0.7em',
			paddingLeft: theme.spacing(2),
			marginBottom: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				paddingLeft: theme.spacing(6),
				fontSize: '1em',
			},
		},
		errorMessage: {
			alignSelf: 'center',
			fontSize: 14,
			color: theme.palette.error.main,
		},
		notchedOutline: {},
		emailHelperText: {
			color: 'black',
			marginTop: theme.spacing(1) - 3,
			fontSize: '0.7em',
			[theme.breakpoints.up('sm')]: {
				padding: `${theme.spacing(0)}px ${theme.spacing(5) - 6}px `,
				fontSize: '1em',
			},
		},
		errorTextColor: {
			color: theme.palette.error.main,
		},
		emailErrorText: {
			color: 'red',
			marginTop: theme.spacing(1) - 3,
			fontSize: '0.7em',
			[theme.breakpoints.up('sm')]: {
				padding: `${theme.spacing(0)}px ${theme.spacing(5) - 6}px `,
				fontSize: '1em',
			},
		},
		shrinkedLabel: {
			fontSize: 15,
			fontWeight: 700,
			[theme.breakpoints.up('sm')]: {
				paddingLeft: theme.spacing(5) + 4,
				fontSize: 17,
			},
		},
		openBankingWarning: {
			textAlign: 'center',
			color: theme.palette.warning.main,
		},
		balanceContainer: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			margin: theme.spacing(2, 0),
			[theme.breakpoints.down('xs')]: {
				flexDirection: 'column',
				alignItems: 'stretch',
				justifyContent: 'flex-start',
			},
		},
		currencyLabel: {
			fontWeight: 700,
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: 48,
			[theme.breakpoints.down('xs')]: {
				fontSize: 35,
			},
		},
		balanceInput: {
			maxWidth: '100%',
			width: '90%',
			margin: theme.spacing(0, 2),
			boxShadow: theme.shadows[3],
			borderRadius: 10,

			'& input': {
				fontSize: 20,
				fontWeight: 700,
				padding: `${theme.spacing(2) - 4}px ${theme.spacing(2)}px`,
				[theme.breakpoints.down('xs')]: {
					fontSize: 22,
					padding: `${theme.spacing(2) - 4}px ${theme.spacing(6)}px ${theme.spacing(2) - 4}px ${theme.spacing(1)}px`,
					textAlign: 'center',
				},
			},
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(0, 0, 2),
			},
		},
		payBtn: {
			textTransform: 'none',
			fontSize: 18,
		},
		buttonProgress: {
			marginLeft: theme.spacing(1) - 2,
		},
		paymentTerm: {
			fontSize: 12,
			lineHeight: '1.2',
			'&:first-of-type': {
				marginTop: theme.spacing(10),
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
		},
		backButton: {
			textTransform: 'none',
			color: theme.palette.secondary.main,
			border: 'none',
			boxShadow: 'none',

			'&:hover': {
				boxShadow: 'none',
			},
		},
		summaryDate: {
			paddingLeft: theme.spacing(6),
			marginBottom: theme.spacing(3),
			[theme.breakpoints.down('xs')]: {
				marginBottom: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				fontSize: 14,
			},
		},
	})
);
