import { ErrorType } from 'components/common';
import { SAVE_INITIAL_PARAMETERS_ENDPOINT, SAVE_INITIAL_PARAMETERS_QUICKPAY_ENDOPOINT } from 'helpers/getApiUrl';

import { ErrorCodes, ApiError } from '../../../../helpers/ApiError';
import { fetchWrapper, RequestMethod } from '../../../../helpers/fetchWrapper';
import { QUICK_PAY_ROUTE } from '../../Configuration/constants';
import { ConfigurationWithPaymentData, InitialParameters } from '../types';

const url = window.location.pathname;

const APP_VERSION_TOKEN = 'App-V';

const isQuickPayPage = url.includes(QUICK_PAY_ROUTE);

const SAVE_PARAMS_ENDPOINT = isQuickPayPage
	? SAVE_INITIAL_PARAMETERS_QUICKPAY_ENDOPOINT
	: SAVE_INITIAL_PARAMETERS_ENDPOINT;

export const saveInitialParameters = (
	initialParameters: InitialParameters = {} as InitialParameters
) => async (): Promise<ConfigurationWithPaymentData> => {
	if (!initialParameters.clientID) {
		throw new ApiError(
			ErrorCodes.notFound,
			'No clientID was found in the requested resource',
			ErrorType.MISSING_CONFIGURATION
		);
	}
	const body = {
		parameters: initialParameters,
	};
	const response = await fetchWrapper(SAVE_PARAMS_ENDPOINT, {
		body,
		method: RequestMethod.POST,
		returnRawResponse: true,
	});
	const paymentData = await response.json();

	return { ...paymentData, appVersion: response.headers.get(APP_VERSION_TOKEN) };
};
