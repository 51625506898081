import Typography from '@material-ui/core/Typography';
import React from 'react';

import { useHeaderStyles } from './styles';

export const Header: React.FunctionComponent<{
	logo: string;
	text?: string;
	hasBackground: boolean;
	links?: { label: string; link: string }[];
}> = (props) => {
	const { logo, text, links } = props;
	const styles = useHeaderStyles(props);

	return (
		<header className={styles.root}>
			{links && (
				<div className={styles.linksContainer}>
					{links.map(({ label, link }) => (
						<a href={link} key={label} className={styles.headerLink}>
							{label}
						</a>
					))}
				</div>
			)}

			{logo && <img src={logo} className={styles.headerLogo} role="presentation" alt="Company Logo" />}
			{text && (
				<Typography variant="h1" className={styles.headerText}>
					{text}
				</Typography>
			)}
		</header>
	);
};
