import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { useFooterStyles } from './styles';

export const Footer: React.FunctionComponent<{
	logo?: string;
	text?: string[] | string;
	links?: { label: string; link: string }[];
	hasBackground?: boolean;
}> = (props) => {
	const { logo, text, links } = props;
	const styles = useFooterStyles(props);
	const textParagraphs = typeof text === 'string' ? [text] : text;

	return (
		<Box component="footer" className={styles.root}>
			{logo && <img src={logo} className={styles.footerLogo} alt="Company Logo Footer" role="presentation" />}
			<Box className={styles.textContainer}>
				{textParagraphs &&
					textParagraphs.map((message, index) => (
						<Typography variant="body2" color="primary" align="center" className={styles.footerParagraph} key={index}>
							{message}
						</Typography>
					))}
				<div className={styles.linksContainer}>
					{links &&
						links.map(({ label, link }) => (
							<a href={link} key={label} className={styles.footerLink}>
								{label}
							</a>
						))}
				</div>
			</Box>
		</Box>
	);
};
