export enum ErrorCodes {
	unauthorized = 401,
	notFound = 404,
}

export class ApiError {
	constructor(public code: number, public description = '', public type?: number) {
		this.code = code;
		this.description = description;
		this.type = type;
	}
}
